<template>
  <div>
    <h2 class="text-center">财务</h2>
    <year-complete/>

    <p class="situation-kpi-title m-t-10 m-b-5">从上线截止今日</p>
    <Row class="table-row-1 p-t-2 p-b-2">
      <i-col span="12" class="remark p-l-5">应回款</i-col>
      <i-col span="12" class="text-right p-r-5">
        {{formatMoney(publisherDebtRateData.targetAmount)}}
      </i-col>
    </Row>
    <Row class="table-row-2 p-t-2 p-b-2">
      <i-col span="12" class="remark p-l-5">已回款</i-col>
      <i-col span="12" class="text-right p-r-5">
        {{formatMoney(publisherDebtRateData.actualAmount)}}
      </i-col>
    </Row>
    <Row class="table-row-1 p-t-2 p-b-2">
      <i-col span="12" class="remark p-l-5">回款率</i-col>
      <i-col span="12" class="text-right p-r-5">
        {{publisherDebtRateData.rate}} %
      </i-col>
    </Row>

    <p class="situation-kpi-title m-t-10 m-b-5">从上线截止今日，应回款逾期账龄分布</p>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="8" class="text-left">账龄</i-col>
      <i-col span="8">金额(元)</i-col>
      <i-col span="8">占比</i-col>
    </Row>
    <Row v-for="(item,index) in listOverdueDurationData" :key="'2_' + index" :gutter="8" class="p-t-5 text-right">
      <i-col span="8" class="text-left">{{item.name}}</i-col>
      <i-col span="8">{{formatNumber(item.value)}}</i-col>
      <i-col span="8">{{item.rate}}%</i-col>
    </Row>
    <p v-if="listOverdueDurationData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <p class="situation-kpi-title m-t-10 m-b-5">查询周期内， 结算分布</p>
    <Row class="text-center">
      <i-col span="24">
        <RadioGroup v-model="chooseFeature" size="small" type="button" button-style="solid">
            <Radio label="1" style="width:162px">履约（成本）</Radio>
            <Radio label="2" style="width:162px">交易（合同执行额）</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row class="p-t-5" :gutter="4">
      <i-col :span="chooseFeature==='1'?12:24">
        <Select size="small" v-model="chooseCompanyId" @on-change="loadBalanceData">
            <Option v-for="item in childCompanyList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col v-if="chooseFeature==='1'" span="12">
        <Select size="small" v-model="chooseSupplierId" @on-change="loadBalanceData">
            <Option v-for="item in supplierList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
    </Row>
    <Row  class="table-title text-right" :gutter="4">
      <i-col span="5" class="text-left">类型</i-col>
      <i-col span="6">金额(元)</i-col>
      <i-col span="4">金额涨幅</i-col>
      <i-col span="4">费效比</i-col>
      <i-col span="5">已结算(元)</i-col>
    </Row>
    <Row v-for="(item,index) in listMaintainBillGbTypeData" :key="'5_' + index" :gutter="8" class="p-t-2 text-right">
      <i-col span="5" class="text-left">{{item.name}}</i-col>
      <i-col span="6">{{formatNumber(item.targetAmount)}}</i-col>
      <i-col span="4">
        <span :class="getChainRateColor(item.number)">{{item.number}}%</span>
      </i-col>
      <i-col span="4">{{item.rate}}%</i-col>
      <i-col span="5">{{formatNumber(item.actualAmount)}}</i-col>
    </Row>
    <p v-if="listMaintainBillGbTypeData.length===0" class="remark p-t-20 text-center">暂无数据</p>

  </div>
</template>

<script>
import YearComplete from '../rightComponents/YearComplete'

import { ParseDate } from '@/utils/dateFormat'
import { toNumber, toMoney } from '@/utils/wnumb_own'

import { getCompany } from '@/api/os/company'
import { listOverdueDuration, listMaintainBillGbType, listExecuteBillGbType } from '@/api/dw/manage'
import { getPublisherDebtRate, listChildrenCompany } from '@/api/dw/datav'

export default {
  components: {
    YearComplete
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      loginCompanyId: this.$store.getters.token.userInfo.companyId,

      listOverdueDurationData: [],
      publisherDebtRateData: {},

      chooseFeature: '1', // 查询类型
      chooseCompanyId: '0',
      chooseSupplierId: '0',
      supplierList: [],
      childCompanyList: [],

      listMaintainBillGbTypeData: []
    }
  },
  created () {
    this.chooseCompanyId = (this.loginCompanyId === this.publisherId) ? '0' : this.loginCompanyId

    this.initPageData()
    this.loadAgentAndSupplier()
    this.loadBalanceData()
  },
  methods: {
    initPageData () {
      const queryModel = {
        publisherId: this.publisherId
      }
      listOverdueDuration(queryModel).then(res => {
        this.listOverdueDurationData = res
      })

      const queryModel2 = {
        publisherId: this.publisherId,
        startDate: '2018-01-01',
        endDate: ParseDate(new Date())
      }
      getPublisherDebtRate(queryModel2).then(res => {
        this.publisherDebtRateData = res[0]
      })
    },
    loadAgentAndSupplier () {
      const postData1 = {
        endDate: this.searchSchedule.endDate,
        loginCompanyId: this.loginCompanyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        type: 3 // 2代理商;3运维公司
      }
      listChildrenCompany(postData1).then(res => {
        this.supplierList = res
      })

      if (this.chooseCompanyId !== '0') { // 表示代理商
        getCompany({ companyId: this.chooseCompanyId }).then(res => {
          if (res && !res.errcode) {
            this.childCompanyList = [
              { id: this.chooseCompanyId, name: res.name }
            ]
          }
        })
      } else {
        const postData2 = {
          endDate: this.searchSchedule.endDate,
          loginCompanyId: this.loginCompanyId,
          publisherId: this.publisherId,
          startDate: this.searchSchedule.startDate,
          type: 2 // 2代理商;3运维公司
        }
        listChildrenCompany(postData2).then(res => {
          this.childCompanyList = res
        })
      }
    },
    loadBalanceData () {
      const queryModel = {
        chainEndDate: this.compareSchedule.endDate,
        chainStartDate: this.compareSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        companyId: this.chooseCompanyId,
        supplierId: this.chooseSupplierId
      }

      if (this.chooseFeature === '1') {
        listMaintainBillGbType(queryModel).then(res => {
          this.listMaintainBillGbTypeData = res
        })
      } else {
        listExecuteBillGbType(queryModel).then(res => {
          this.listMaintainBillGbTypeData = res
        })
      }
    },
    formatNumber (number) {
      return toNumber(number)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    getChainRateColor (number) {
      let color = 'text-red'
      if (number < 0) {
        color = 'text-green'
      } else if (number === 0) {
        color = 'text-yellow'
      }
      return color
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    compareSchedule () {
      return this.$store.state.situationWeb.compareSchedule
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.chooseCompanyId = (this.loginCompanyId === this.publisherId) ? '0' : this.loginCompanyId
        this.chooseSupplierId = '0'
        this.loadBalanceData()
      }
    },
    compareSchedule: {
      deep: true,
      handler (newValue) {
        this.chooseCompanyId = (this.loginCompanyId === this.publisherId) ? '0' : this.loginCompanyId
        this.chooseSupplierId = '0'
        this.loadBalanceData()
      }
    },
    chooseFeature () {
      this.loadBalanceData()
    }
  }
}
</script>
